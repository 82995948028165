import React, { createContext, useMemo, useState } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Typography,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Cookies from "js-cookie";

import whiteLogo from "../logo.png";
import blackLogo from "../logo-black.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderLink from "./HeaderLink";
import useIsMobile from "../Hooks/useIsMobile";
import { getHeaderHeight } from "../utils/helpers";
import venueCover from "../images/venuecover.jpeg";
import clocktower from "../images/clocktower.jpeg";
import ChangeLanguage from "./ChangeLanguage";

const drawerWidth = 240;

const pages = [
  // { label: "Venue", path: "/venue" },
  // { label: "Accommodation", path: "/accommodation" },
];

export const EN = "en";
export const AT = "at";

export const AppContext = createContext(null);

const Layout = ({ children, header }) => {
  let defaultLocale = "enUS";

  if (Cookies.get("locale")) {
    defaultLocale = Cookies.get("locale");
  }

  const [locale, setLocale] = useState({
    locale: defaultLocale,
    language: Cookies.get("lang") ?? EN,
  });

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const isMobile = useIsMobile();
  const headerHeight = header ? getHeaderHeight(isMobile) : "0px";

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        py={2}
        justifyContent="center"
      >
        <Link to="/" style={{ display: "flex", alignItems: "center" }}>
          <img src={blackLogo} alt="Wedding rings" width="50px" />
        </Link>
      </Box>

      <Divider />
      <List>
        {pages.map(({ path, label }) => (
          <ListItem key={label} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText>
                <HeaderLink isMobile to={path}>
                  {label}
                </HeaderLink>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const contextValue = useMemo(
    () => ({
      locale,
      setLocale,
    }),
    [locale, setLocale]
  );

  return (
    <AppContext.Provider value={contextValue}>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          backgroundColor: "rgba(0,0,0,0.7)",
        }}
      >
        <CssBaseline />
        {header && (
          <>
            <AppBar component="nav">
              <Toolbar>
                <IconButton
                  color="white"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" }, color: "#fff" }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", sm: "flex" },
                    alignItems: "center",
                  }}
                >
                  <Link
                    to="/"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img src={whiteLogo} alt="Wedding rings" width="50px" />
                  </Link>
                </Typography>
                <Box
                  sx={{ display: { xs: "flex", sm: "none" } }}
                  width="100%"
                  justifyContent="end"
                >
                  {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate("/rsvp")}
                  >
                    <HeaderLink to="/rsvp" sx={{ ml: 0 }}>
                      R.S.V.P
                    </HeaderLink>
                  </Button> */}
                </Box>

                <Box
                  alignItems="center"
                  sx={{ display: { xs: "none", sm: "flex" } }}
                >
                  {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate("/rsvp")}
                  >
                    <HeaderLink to="/rsvp" sx={{ ml: 0 }}>
                      R.S.V.P
                    </HeaderLink>
                  </Button> */}
                  {pages.map(({ label, path }) => (
                    <HeaderLink key={label} to={path} hasMargin>
                      {label}
                    </HeaderLink>
                  ))}
                  {location.pathname !== "/" && <ChangeLanguage />}
                </Box>
                {/* Mobile */}
                {location.pathname !== "/" && isMobile && <ChangeLanguage />}
              </Toolbar>
            </AppBar>
            <nav>
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </Drawer>
            </nav>
          </>
        )}
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          minHeight={`calc(100vh - ${headerHeight})`}
          flexDirection="column"
          sx={{
            backgroundImage: `url(${isMobile ? clocktower : venueCover})`,
            backgroundSize: "100% 100%;",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "100%",
            minHeight: `calc(100vh - ${headerHeight})`,
            position: "relative",
            paddingBottom: 3,
            backgroundAttachment: "fixed",
          }}
          zIndex="0"
          pt={5}
          mt={headerHeight}
        >
          <Box
            display="flex"
            width="100%"
            position="absolute"
            top="0"
            left="0"
            bgcolor="rgba(255,255,255,0.4)"
            minHeight="100%"
            zIndex="1"
          />
          {children}
        </Box>
      </Box>
    </AppContext.Provider>
  );
};

export default Layout;
