import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRoute = () => {
  const hasSelectedLanguage =
    (Cookies.get("locale") && Cookies.get("locale") === "enUS") ||
    Cookies.get("locale") === "deDE";

  console.log(
    "🚀 ~ ProtectedRoute ~ hasSelectedLanguage:",
    hasSelectedLanguage
  );
  return hasSelectedLanguage ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
