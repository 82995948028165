import React, { useContext } from "react";
import {
  Button,
  Typography,
  useTheme,
  CardContent as MuiCardContent,
  Grid,
  Alert,
} from "@mui/material";
import styled from "@emotion/styled";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useIsMobile from "../Hooks/useIsMobile";
import { Link, useNavigate } from "react-router-dom";

import Card from "./Card";
import { AppContext, EN } from "./Layout";

const CardContent = styled(MuiCardContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => `${theme.breakpoints.between("xs", "md")} {
    width: 100%;
  }`}
  ${({ theme }) => `${theme.breakpoints.between("md", "xl")} {
    width: 70%;
  }`}
  ${({ theme }) => `${theme.breakpoints.up("xl")} {
    width: 62%;
  }`}
`;

const Home = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    locale: { language },
  } = useContext(AppContext);

  return (
    <Card align="center">
      <CardContent sx={{ mt: 2 }}>
        <Typography variant="h5">
          {language === EN
            ? "Welcome, coming soon!"
            : "Willkommen, bald verfügbar!"}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Home;
