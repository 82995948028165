import React, { useContext } from "react";
import Card from "./Card";
import CardContent from "./CardContent";
import { IconButton, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Cookies from "js-cookie";

import uk from "../images/united-kingdom.png";
import aus from "../images/austria.png";
import { AppContext, AT, EN } from "./Layout";
import { useNavigate } from "react-router-dom";

const LanguageLanding = () => {
  const navigate = useNavigate();
  const { setLocale } = useContext(AppContext);

  const onClick = (lang) => {
    Cookies.set("locale", lang === EN ? "enUS" : "deDE");
    Cookies.set("lang", lang);
    setLocale({ language: lang, locale: lang === EN ? "enUS" : "deDE" });
    navigate("/home");
  };

  return (
    <Card align="center">
      <CardContent sx={{ mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Welcome, please choose a language:
        </Typography>
        <Typography variant="h6" sx={{ opacity: 0.7 }}>
          Willkommen, bitte wählen Sie eine Sprache:
        </Typography>
        <Grid2 container justifyContent="space-evenly" sx={{ mt: 3 }}>
          <Grid2
            size={{ xs: 6 }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <IconButton
              onClick={() => onClick(EN)}
              size="large"
              aria-label="select language"
              aria-controls="lang-select"
              aria-haspopup="true"
              color="inherit"
              sx={{
                backgroundImage: `url(${uk})`,
                backgroundSize: "100% 100%;",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "4rem",
                height: "4rem",
              }}
            />
            <Typography variant="body1" sx={{ mt: 1 }}>
              English
            </Typography>
          </Grid2>
          <Grid2
            size={{ xs: 6 }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <IconButton
              onClick={() => onClick(AT)}
              size="large"
              aria-label="select language"
              aria-controls="lang-select"
              aria-haspopup="true"
              color="inherit"
              sx={{
                backgroundImage: `url(${aus})`,
                backgroundSize: "100% 100%;",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "4rem",
                height: "4rem",
              }}
            />
            <Typography variant="body1" sx={{ mt: 1 }}>
              Deutsche
            </Typography>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};

export default LanguageLanding;
