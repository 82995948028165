import { createTheme } from "@mui/material";
import { enUS, deDE } from "@mui/material/locale";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#97b2a1",
        light: "#bdd0c4",
        dark: "#73967f",
        contrastText: "#000",
      },
      secondary: {
        main: "#507667",
        light: "#a8aec9",
        dark: "#6f75a2",
        extraDark: "#005d95",
        contrastText: "#fff",
      },
      grey: {
        main: "#545454",
      },
      silver: {
        main: "#A6A6A6",
      },
    },
    typography: {
      fontFamily: `"Montserrat", sans-serif`,
      fontWeightRegular: 300,
    },
  },
  localStorage.getItem("locale") && localStorage.getItem("locale") === "enUS"
    ? enUS
    : deDE
);

export default theme;
