import React, { useContext } from "react";
import Cookies from "js-cookie";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import uk from "../images/united-kingdom.png";
import aus from "../images/austria.png";
import { AppContext, AT, EN } from "./Layout";

const ChangeLanguage = () => {
  const { setLocale, locale } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (lang) => {
    Cookies.set("locale", lang === EN ? "enUS" : "deDE");
    Cookies.set("lang", lang);
    setLocale({ language: lang, locale: lang === EN ? "enUS" : "deDE" });
    setAnchorEl(null);
  };

  return (
    <Box sx={{ ml: 2 }}>
      <IconButton
        size="large"
        aria-label="select language"
        aria-controls="lang-select"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        sx={{
          backgroundImage: `url(${locale.language === EN ? uk : aus})`,
          backgroundSize: "100% 100%;",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Menu
        id="lang-select"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => onClick(EN)}>English</MenuItem>
        <MenuItem onClick={() => onClick(AT)}>Deutch</MenuItem>
      </Menu>
    </Box>
  );
};

export default ChangeLanguage;
